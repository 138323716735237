.section-form-validation {
    position: absolute;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    z-index: 100;
    display: none;

    background-image: url('../assets/images/seats.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    background-color: $bgRed; }

.section-form-validation__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    overflow: visible;

    width: 75%;
    height: 100%;
    margin: auto;

    @media screen and ( max-width: 560px ) {
        width: 85%;

        .section-form-validation__paragraph {
            font-size: 30px; }

        .section-form-validation__button {
            min-width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 16px; } } }

.section-form-validation__players-images-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    width: 35%;

    @media screen and ( max-width: 1100px ) {
        display: none; } }


.section-form-validation__player-image-container {
    position: relative;

    width: 50%;

    img {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: auto;

        transform: translateY(-50%); } }

.section-form-validation__content {
    width: 50%;

    color: white;

    @media screen and ( max-width: 1100px ) {
        width: 100%; } }

.section-form-validation__text-container {
    // display: flex
 }    // align-items: center

.section-form-validation__validation-box {
    display: inline-block;
    position: relative;

    width: 70px;
    height: 70px;

    margin-right: 80px;

    background-color: $green-front;

    @media screen and (max-width: 1324px) {
        width: 60px;
        height: 60px; }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;

        margin: auto;

        width: 50%;
        height: 50%;

        path {
            fill: white; } }

    &:before {
        position: absolute;
        left: 0;
        bottom: 100%;

        width: 100%;
        height: 35%;

        content: '';

        background-color: $green-top;

        display: block;
        transform: skewX(-40deg);
        transform-origin: left bottom; }

    &:after {
        position: absolute;
        left: 100%;
        bottom: 0;

        width: 30%;
        height: 100%;

        content: '';

        background-color: $green-right;

        transform: skewY(-50deg);
        transform-origin: left top;

        border-top: 1px solid $green-right; } }

.section-form-validation__paragraph {
    display: inline-block;

    font-size: 45px;

    @media screen and (max-width: 1324px) {
        font-size: 35px; } }

.section-form-validation__social {
    font-size: 15px;
    line-height: 20px;

    margin-top: 40px;

    opacity: 0.7; }

.section-form-validation__buttons-container {
    display: flex;
    justify-content: space-between;

    margin-top: 60px; }

.section-form-validation__button {
    position: relative;

    display: inline-block;
    vertical-align: baseline;

    width: auto;
    min-width: 70px;
    height: 60px;

    box-sizing: border-box;

    padding: 0 30px;

    line-height: 60px;
    font-size: 20px;
    text-transform: uppercase;

    background: #a72b2e;

    border: none;
    color: white;
    text-decoration: none;

    transition: transform 0.2s ease-in-out;

    &::before {
        position: absolute;

        left: 0;
        bottom: 100%;

        width: 100%;
        height: 30%;

        background-color: $red-top;

        content: "";

        transform-origin: left bottom;
        transform: skewX(-40deg);

        transition: height 0.2s ease-in-out; }

    &:hover {
        transform: translate(5px, -5px);
        &:before {
            height: 22%;
 } } }            // left: 10.5px

.section-form-validation__button-icon {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    margin: auto;

    width: 40px;
    height: auto; }

.section-form-validation__credits-container {
    color: white;
    text-transform: uppercase;
    font-size: 12px;

    width: 100%;
    margin: 0 auto;

    position: absolute;
    bottom: 30px;

    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 1080px) {
        span {
            max-width: 120px; } }
    @media screen and (max-width: 1080px) {
        flex-flow: column;

        text-align: center;
        span {
            max-width: 100%;
            margin: 1px 0; } } }

@media screen and (orientation: landscape) and (max-height: 420px) {
    .section-form-validation__credits-container {
        display: none; } }

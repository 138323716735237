.sound-button {
    display: none;
    align-items: center;

    position: absolute;
    left: 20px;
    bottom: 20px;

    width: 60px;
    height: 40px;

    border: none;
    outline: none;
    border-radius: 0;
    cursor: pointer;
    // z-index: 100

    background-color: $bgRed;

    &.is-active {
        display: flex; } }

.sound-button__icon {
    height: 80%;
    width: 40%;

    margin: auto 0; }

.sound-button__lines-container {
    display: flex;
    justify-content: center;

    width: 100%;
    height: 50%; }

.sound-button__line {
    width: 4px;
    height: 20px;

    margin: 0 1px;

    background: white;

    transform-origin: left bottom;
    transform: scaleY(0.15);

    &:last-child {
        margin-right: 0; } }


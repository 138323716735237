.game__infos__countdown {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    transform: skewX(4.5deg) rotate(4.5deg);

    @media screen and (orientation: landscape) and (max-width: 1000px) and (max-height: 500px) {
        transform: skewX(4.5deg) rotate(4.5deg) scale(0.6); } }


.countdown__box-container {
    position: relative;

    padding: 30px 50px;

    text-align: center;
    font-size: 90px;

    background-color: #7f2224;
    color: white;

    pointer-events: none;

 }    // transform: skewX(4.5deg) rotate(4.5deg)

.countdown__box-top {
    position: absolute;
    bottom: 100%;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: #FC4846;

    transform-origin: left bottom;

    transform: skewX(-55deg) scaleY(.25);

    &--shadow {} }

.countdown__box-front {
    width: 100%;
    height: 100%;
    overflow: hidden; }

.countdown__box-right {
    position: absolute;
    top: 0;
    left: 100%;

    width: 100%;
    height: 100%;

    background-color: #cf363a;

    transform-origin: left top;

    transform: skewY(-35deg) scaleX(.34); }

.countdown__content {
    display: inline-block; }

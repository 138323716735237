.container__loader {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: $bgRed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    .loader__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-direction: column;
        align-items: center;
        display: flex;
        .content__logo {
            width: 150px; }
        .content__progress {
            color: white;
            margin-top: 5%;
            display: inline-block;
            opacity: 0;
            transform: translateY(100%); } } }

html {
  position: relative;
  overflow: hidden;
  height: 200vh;
  background-color: #ce3739; }

body {
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  background-color: #ce3739;
  color: black;
  font-family: 'Krungthep', sans-serif;
  overflow: hidden;

  .tap-target {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    width: 100%;
    height: 100%; } }

[data-link] {
  cursor: pointer; }

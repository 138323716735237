.canvas {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    margin: auto; }

.canvas-container {
    position: absolute;
    left: 0;
    top: 0;

    transform-origin: left top;
    pointer-events: none;

    width: 100%;
    height: 100%; }

.game__infos {
    &-borders {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        border: 12px solid #ce3739;
        box-sizing: border-box; }
    &-logo-jahneration {
        width: 200px;
        position: absolute;
        top: 20px;
        left: 20px; } }

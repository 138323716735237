// :root
//     --vh: 1vh

$bgRed: #d83d3f;
$bordersRed: #ce3739;
$red-top: #FC4846;

$krungthep: "Krungthep";

$green-right: #29B236;
$green-top: #33CC3C;
$green-front: #229931;

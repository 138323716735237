.container__home {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: red;
    visibility: hidden;
    &.hidden {
        visibility: hidden; } }
.home__logo {
    color: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
.home_start {
    position: absolute; }

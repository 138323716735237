.fullscreen-button {
    position: absolute;
    bottom: 20px;
    right: 20px;

    width: 60px;
    height: 60px;

    border: none;
    outline: none;
    background: none;
    box-sizing: border-box;
    padding: 0;

    display: none;
    opacity: 0;

    transition: opacity 0.1s ease-in-out 0s;

    &.is-enable {
        opacity: 1;
        display: block;
        transition: opacity 0.1s ease-in-out 0.5s; }

    &.is-active {} }

.fullscreen-button__image {
    width: 100%;
    height: 100%; }

@media screen and (orientation: landscape) and (max-width: 1000px) and (max-height: 500px) {
    .fullscreen-button {
        transform-origin: right bottom;
        transform: scale(0.6); } }

.section-mobile-orientation {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    z-index: 9999;

    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background: $bordersRed;

    &.isDisable {
        display: none;
        visibility: hidden;
        pointer-events: none; } }

.section-mobile-orientation__message {
    text-align: center;
    display: block;
    margin: auto;
    color: white; }

.section-mobile-orientation__image {
    display: block;
    width: 70%;
    height: auto;
    margin: auto;
    margin-bottom: 40px; }

.game__infos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    // z-index: 1000
 }    // pointer-events: none

.infos__timer {
    color: white;
    font-size: 65px;
    z-index: 0;
    position: absolute;
    bottom: -50px;
    right: -50px;
    width: 450px;
    height: 300px;
    transform: skewX(4.5deg) rotate(4.5deg);

    pointer-events: all;

    .timer-value {
        position: absolute;
        right: 30%;
        text-shadow: rgba(0, 0, 0, 0.4) 5px -3px; }
    .timer-counter {
        width: 250px;
        height: 250px;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        background-color: black; }
    .timer-box {
        .box-front {
            position: absolute;
            background-color: #7f2224;
            height: 350px;
            width: 350px;
            bottom: -125px; }
        .box-top {
            position: absolute;
            background-color: #f74040;
            height: 350px;
            width: 350px;
            transform: skewX(-55deg);
            bottom: 75%;
            right: -150px; }
        .box-right {
            position: absolute;
            height: 350px;
            width: 350px;
            right: -250px;
            bottom: -2px;
            background-color: #8a2525;
            transform: skewY(-35deg); }
        .progress-bar {
            position: absolute;
            width: 350px;
            height: 150px;
            bottom: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .progress-bar-lines {
                width: 265px;
                position: absolute;
                height: 80px;
                display: flex;
                right: -10px;
                bottom: 20px;
                .line {
                    width: calc(100% / 3);
                    &:after {
                        width: 0px;
                        font-size: 30px;
                        content: "|";
                        letter-spacing: 0;
                        position: absolute; } }
                .line-right {
                    text-align: right; }
                .line-centered {
                    text-align: center; } }
            .progress-bar-transparent {
                position: absolute;
                height: 30px;
                width: 265px;
                .progress-bar-front {
                    position: absolute;
                    height: 30px;
                    width: 265px;
                    background-color: rgba(255, 255, 255, 0.2);
                    .front-progress {
                        position: absolute;
                        left: 0;
                        width: 0;
                        height: 100%;
                        background-color: #b2b2b2; } }
                .progress-bar-head {
                    position: absolute;
                    height: 30px;
                    width: 265px;
                    bottom: 100px;
                    .head-progress {
                        position: absolute;
                        left: 0;
                        width: 0;
                        height: 100%;
                        img {
                            position: absolute;
                            right: 0;
                            transform: translateX(60%) rotate(-5deg);
                            width: 100px; } } }
                .progress-bar-top {
                    position: absolute;
                    height: 15px;
                    width: 265px;
                    background-color: rgba(255, 255, 255, 0.6);
                    bottom: 30px;
                    right: -10px;
                    transform: skewX(-55deg);
                    .top-progress {
                        position: absolute;
                        left: 0;
                        width: 0;
                        height: 100%;
                        background-color: #fff; } }
                .progress-bar-right {
                    position: absolute;
                    height: 30px;
                    bottom: 7px;
                    right: -20px;
                    width: 20px;
                    background-color: rgba(255, 255, 255, 0.4);
                    transform: skewY(-35deg); } } } }

    @media screen and (max-width: 1250px) {
        bottom: 20px;
        right: 20px;
        transform: skewX(0) rotate(0);

        width: 350px;
        height: 100px;

        &.is-fullscreen-enable {
            right: calc(20px + 60px + 10px); }

        .timer-box {
            width: 100%;
            height: 100%;

            .box-front {
                position: absolute;
                left: 0;
                top: 0;

                width: 100%;
                height: 100%;

                background-color: #ce3739; }

            .box-top {
                display: none; }

            .box-right {
                display: none; }

            .progress-bar {
                position: absolute;
                left: 0;
                top: 0;

                width: 100%;
                height: 100%;

                display: block;

                .progress-bar-lines {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: auto;

                    height: 70px;

                    margin: auto;

                    .line {
                        &:after {
                            position: absolute;
                            left: 0;
                            top: 15px;

                            height: 15px;
                            width: 0;

                            border: solid 1px white;

                            content: ''; }
                        &.line-centered {
                            &:after {
                                left: 0;
                                right: 0;

                                margin: auto; } }
                        &.line-right {
                            &:after {
                                left: auto;
                                right: 0; } } } }


                .progress-bar-transparent {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;

                    width: 85%;

                    margin: auto;

                    .progress-bar-lines {
                        width: 100%; }

                    .progress-bar-front {
                        width: 100%; }

                    .progress-bar-head {
                        top: 0;
                        width: 100%;

                        .head-progress {
                            top: 0;
                            left: 0;

                            img {
                                top: 0;
                                bottom: 0;
                                margin: auto;

                                z-index: 1; } } } }

                .progress-bar-front {
                    width: 100%;

                    .front-progress {
                        background-color: white !important; } }

                .progress-bar-top {
                    display: none; }

                .progress-bar-right {
                    display: none; } } }

        .timer-value {
            position: absolute;
            top: -45px;
            right: auto;
            left: 5%;
            text-shadow: transparent 2px 5px; } }

    @media screen and (orientation:landscape) and (max-width: 1000px) and (max-height: 600px) {
        transform-origin: right bottom;
        transform: scale(0.6);

        &.is-fullscreen-enable {
            right: calc(20px + #{60 * 0.6}px + 10px); } } }

.section-looser {
    position: fixed;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    user-select: none;
    pointer-events: none;

    background-color: #D83D3F;

    z-index: 9999; }

.section-looser__box-container {
    position: relative;

    @media screen and (orientation: landscape) and (max-width: 1000px) and (max-height: 500px) {
        transform: scale(0.6); } }

.section-looser__box {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    font-size: 0px;

    transform: skewY(2.6deg); }

.section-looser__box-top {
    position: absolute;
    left: 0;
    bottom: 95%;

    width: 100%;
    height: 100%;

    background-color: #FC4846;

    content: '';

    transform-origin: center bottom;
    transform: rotateX(83deg) skewX(-10deg); }

.section-looser__box-right {
    position: absolute;
    left: 100%;
    top: 5%;

    width: 62%;
    height: 100%;

    background-color: #cf363a;

    content: '';

    transform-origin: left top;
    transform: rotateY(83deg) skewY(-4.9deg); }

.section-looser__content {
    transform-origin: left bottom;
    transform: skewY(2.6deg);
    padding: 60px 40px 20px 40px;

    background-color: #a72b2e;

    overflow: hidden; }

.section-looser__heading {
    position: relative;

    color: rgba(255, 255, 255, .5);
    text-transform: uppercase;
    font-size: 90px;

    &--shadow {
        position: absolute;
        left: 0;
        top: 0;

        color: white; } }

.section-looser__subheading {
    margin-top: 20px;

    text-align: center;
    color: white;

    font-size: 30px;

    animation: blink .3s infinite alternate; }

@keyframes blink {
    0% {
        opacity: 1; }

    100% {
        opacity: 0; } }

@media screen and (max-height: 400px) {
    .section-looser__box-container {
        transform: scale(0.6); } }


.game__infos__controlsIndications {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    color: white;
    text-transform: uppercase; }

.game__infos__controlsIndications__container {
    position: relative;

    transform: skewX(4.5deg) rotate(4.5deg); }

.game__infos__controlsIndications__title-container {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;

    transform: translateY(-50%);

    text-align: center; }

.game__infos__controlsIndications__key-content-container {
    overflow: hidden; }

.game__infos__controlsIndications__title {
    display: inline-block;

    position: relative;

    padding: 5px 14px;

    color: black;
    background-color: #B2B2B2;

    font-size: 22px; }


.game__infos__controlsIndications__content {
    max-width: 400px;
    min-height: 240px;

    padding: 60px 50px;
    padding-bottom: 80px;

    background: #B23435;
    color: #B2B2B2;

    text-transform: none;
    font-size: 30px;
    line-height: 40px; }

.game__infos__controlsIndications__key-container {
    position: absolute;
    left: 0;
    top: 100%;

    transform: translate(-5%, -50%);

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 40px; }

.game__infos__controlsIndications__key {
    position: relative;

    background: #B23435;

    text-align: center;
    width: 350px;
    height: 40px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    padding: 10px 0;
    margin: auto;

    &--left, &--right {
        width: 65px;
        height: 40px;

        position: relative;
        margin: 0 10px;

        .key__boxRight {
            width: 40%; } } }

.game__infos__controlsIndications__check {
    width: 40px;
    height: 40px;

    display: none; }

.game__infos__controlsIndications__key-content-container {
    overflow: hidden; }

.title__boxTop {
    position: absolute;
    left: 0;
    bottom: 100%;

    width: 100%;
    height: 30%;

    background-color: #FFFFFF;
    border-right: solid 1px #fff;

    transform-origin: left bottom;
    transform: skewX(-55deg);

    font-size: 0; }

.title__boxRight {
    position: absolute;
    left: 100%;
    top: 0;

    width: 8%;
    height: 100%;

    background-color: #D3D2D1;

    transform-origin: left bottom;
    transform: skewY(-35deg);

    font-size: 0; }

.key__boxTop {
    position: absolute;
    left: 0;
    bottom: 100%;

    width: 100%;
    height: 30%;

    background-color: #FC4846;

    transform-origin: left bottom;
    transform: skewX(-55deg);

    font-size: 0; }

.key__boxRight {
    position: absolute;
    left: 100%;
    top: 0;

    width: 8%;
    height: 100%;

    background-color: #cf363a;

    transform-origin: left bottom;
    transform: skewY(-35deg);

    font-size: 0; }


@media screen and (max-height: 600px) {
    .game__infos__controlsIndications {
        transform: scale(0.6); } }

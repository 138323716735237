.section-cinematic {
    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: $bgRed;

    // z-index: 100000
    opacity: 0;
    visibility: hidden;

    pointer-events: none; }

.section-cinematic__video {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: auto; }

@use "sass:math" {}

.container {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative; }

.container__playerSelect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    visibility: visible;

    background-color: $bgRed;
    opacity: 1;

    &.hidden {
        visibility: hidden;
        opacity: 0; } }

.playerSelect__skewedBackground {
    background-color: #FC4846;
    position: absolute;
    left: 0;
    top: 50px;

    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    transform: skewY(5.125deg); }
.playerSelect__logo {
    position: absolute;
    top: 20px;
    left: 20px;
    img {
        width: 200px; } }
.playerSelect__text {
    transform: skewY(5.125deg);
    text-transform: uppercase;
    color: white;
    font-size: 50px;
    text-align: left;
    text-shadow: 1px -2px 1px black;

    .char {
        opacity: 0;
        transform: rotate(0) translateY(0);
        transition: opacity .5s ease-in-out;
        transition-property: transform, opacity; }

    &.transitionIn {
        .char {
            opacity: 1;
            transform: rotate(0) translateY(0);
            @for $i from 0 through 30 {
                &--#{$i} {
                    transition-delay: #{$i * 0.05}s; } } } }

    .fontSize-player {
        font-size: 71px; } }

.playerSelect__players {
    display: flex;
    justify-content: center;

    width: 100%;

    margin-top: 100px;

    transform: scale(1.1) translateX(-60px); }

.player__first, .player__second {
    width: 200px;
    margin: auto 0;

    cursor: pointer;

    & img {
        width: 100%;
        position: absolute;
        transition: transform .2s ease-in-out; }

    &:hover, &.isActive {
        .player__head {
            transform: translateY(-20px); }

        .player__name {
            transform: translate(calc(34% + 5px) , calc(-103% - 3px)) skewY(5.125deg);

            &:before {
                width: 20px;
                height: 30px;
                right: -20%;
                top: -6px; } } }

    &:hover {
        .player__name-content {
            transform: translateY(-100%);

            &:before {
                transition: opacity 0.05s ease-in-out 0s;
                opacity: 1; } } } }

.player__first {
    transform: translate(-5%, -8.4%); }

.player {
    position: relative;
    height: 300px;

    &__background {
        background-color: #c9c7cb;
        width: 90px;
        height: 180px;
        position: absolute;
        left: 50%;
        top: 5px;
        transform: translateY(-15%) translateX(10%) skewY(5.125deg); }
    &__name {
        background-color: #a72b2e;
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        position: absolute;
        text-transform: uppercase;
        color: white;
        transform: translate(34%,-103%) skewY(5.125deg);
        transition: transform .2s ease-in-out;

        &::before {
            display: inline-block;
            content: "";
            width: 25px;
            height: 30px;
            background-color: #cf363a;
            right: -25%;
            top: -7px;
            position: absolute;
            transform: skewY(-30.125deg);
            transition: all .2s ease-in-out; } } }

.player__name-content-container {
    display: block;
    overflow: hidden;
    line-height: 18px; }

.player__name-content {
    position: relative;
    display: block;
    transition: transform .2s ease-in-out;

    &:before {
        content: attr(data-content);

        position: absolute;
        left: 0;
        top: 100%;

        opacity: 0;
        transition: opacity 0.05s ease-in-out 0.2s; } }

.player__head {
    position: absolute;
    left: 0;
    top: 0; }

.bg__transition {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    width: 110vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    margin: auto;

    pointer-events: none;
    z-index: 200;

    transform: rotate(-45deg);
    visibility: hidden;
    opacity: 0;

    // debug
    // width: 200px
    // height: 90px
    // background: black
    // overflow: initial

    &-red {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;

        margin: auto;

        width: 100%;
        height: 300px;

        background: #FC4846; }

    &-white {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;

        margin: auto;

        width: 100%;
        height: 300px;

        background: white; } }

@media screen and (max-width: 640px) {
    .bg__transition {
        display: none; }
    .playerSelect__text {
        font-size: 25px;
        .fontSize-player {
            font-size: 35px; } }
    .playerSelect__logo {
        left: 50%;
        transform: translateX(-50%);
        img {
            width: 100px; } }
    .player__first {
        transform: translate(-5%,-3.4%); }
    .player {
        height: 260px;
        &__background {
            height: 140px;
            width: 70px; } }
    .playerSelect__players {
        transform: scale(0.8) translateX(0px); } }

@media screen and (max-height: 500px) and (orientation: landscape) {
    .container__playerSelect {}

    .playerSelect__logo {
        top: 10px;
        left: 10px;

        width: 150px;
        height: auto;

        img {
            width: 100%;
            height: auto; } }

    .playerSelect__text {
        margin-top: 20px;
        font-size: 24px;

        .fontSize-player {
            font-size: 34px; } }

    .playerSelect__players {
        margin-top: 50px;
        transform: scale(1.1) translateX(-32px); }

    .player__background {
        width: 54px;
        height: 107px; }

    .playerSelect__players {
        .player {
            height: 200px;
            width: 120px;
            // height: 250px

            &:hover, &.isActive {
                .player__name {
                    transform: translate(calc(34% + 5px) , calc(-103% - 3px)) skewY(5.125deg);

                    &:before {
                        width: 20px;
                        height: 20px;
                        right: -20%;
                        top: -6px; } } } } }

    .player__name {
        bottom: 20px;
        position: absolute;

        width: 80px;
        height: 20px;

        font-size: 10px;
        background-color: #a72b2e;

        transform: translate(34%,-103%) skewY(5.125deg);

        &::before {
            position: absolute;
            right: -19%;
            top: -4px;

            width: 15px;
            height: 20px;

            transform: skewY(-30.125deg); } } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  color: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  position: relative;
  overflow: hidden;
  height: 200vh;
  background-color: #ce3739; }

body {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  background-color: #ce3739;
  color: black;
  font-family: 'Krungthep', sans-serif;
  overflow: hidden; }
  body .tap-target {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }

[data-link] {
  cursor: pointer; }

@font-face {
  font-family: "Krungthep";
  src: url("../assets/fonts/Krungthep/Krungthep.eot");
  src: url("../assets/fonts/Krungthep/Krungthep.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Krungthep/Krungthep.woff2") format("woff2"), url("../assets/fonts/Krungthep/Krungthep.woff") format("woff"), url("../assets/fonts/Krungthep/Krungthep.ttf") format("truetype"), url("../assets/fonts/Krungthep/Krungthep.svg") format("svg");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale; }

.container__playerSelect {
  z-index: 99; }

@use "sass:math" {}.container {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative; }

.container__playerSelect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  visibility: visible;
  background-color: #d83d3f;
  opacity: 1; }
  .container__playerSelect.hidden {
    visibility: hidden;
    opacity: 0; }

.playerSelect__skewedBackground {
  background-color: #FC4846;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transform: skewY(5.125deg); }

.playerSelect__logo {
  position: absolute;
  top: 20px;
  left: 20px; }
  .playerSelect__logo img {
    width: 200px; }

.playerSelect__text {
  transform: skewY(5.125deg);
  text-transform: uppercase;
  color: white;
  font-size: 50px;
  text-align: left;
  text-shadow: 1px -2px 1px black; }
  .playerSelect__text .char {
    opacity: 0;
    transform: rotate(0) translateY(0);
    transition: opacity .5s ease-in-out;
    transition-property: transform, opacity; }
  .playerSelect__text.transitionIn .char {
    opacity: 1;
    transform: rotate(0) translateY(0); }
    .playerSelect__text.transitionIn .char--0 {
      transition-delay: 0s; }
    .playerSelect__text.transitionIn .char--1 {
      transition-delay: 0.05s; }
    .playerSelect__text.transitionIn .char--2 {
      transition-delay: 0.1s; }
    .playerSelect__text.transitionIn .char--3 {
      transition-delay: 0.15s; }
    .playerSelect__text.transitionIn .char--4 {
      transition-delay: 0.2s; }
    .playerSelect__text.transitionIn .char--5 {
      transition-delay: 0.25s; }
    .playerSelect__text.transitionIn .char--6 {
      transition-delay: 0.3s; }
    .playerSelect__text.transitionIn .char--7 {
      transition-delay: 0.35s; }
    .playerSelect__text.transitionIn .char--8 {
      transition-delay: 0.4s; }
    .playerSelect__text.transitionIn .char--9 {
      transition-delay: 0.45s; }
    .playerSelect__text.transitionIn .char--10 {
      transition-delay: 0.5s; }
    .playerSelect__text.transitionIn .char--11 {
      transition-delay: 0.55s; }
    .playerSelect__text.transitionIn .char--12 {
      transition-delay: 0.6s; }
    .playerSelect__text.transitionIn .char--13 {
      transition-delay: 0.65s; }
    .playerSelect__text.transitionIn .char--14 {
      transition-delay: 0.7s; }
    .playerSelect__text.transitionIn .char--15 {
      transition-delay: 0.75s; }
    .playerSelect__text.transitionIn .char--16 {
      transition-delay: 0.8s; }
    .playerSelect__text.transitionIn .char--17 {
      transition-delay: 0.85s; }
    .playerSelect__text.transitionIn .char--18 {
      transition-delay: 0.9s; }
    .playerSelect__text.transitionIn .char--19 {
      transition-delay: 0.95s; }
    .playerSelect__text.transitionIn .char--20 {
      transition-delay: 1s; }
    .playerSelect__text.transitionIn .char--21 {
      transition-delay: 1.05s; }
    .playerSelect__text.transitionIn .char--22 {
      transition-delay: 1.1s; }
    .playerSelect__text.transitionIn .char--23 {
      transition-delay: 1.15s; }
    .playerSelect__text.transitionIn .char--24 {
      transition-delay: 1.2s; }
    .playerSelect__text.transitionIn .char--25 {
      transition-delay: 1.25s; }
    .playerSelect__text.transitionIn .char--26 {
      transition-delay: 1.3s; }
    .playerSelect__text.transitionIn .char--27 {
      transition-delay: 1.35s; }
    .playerSelect__text.transitionIn .char--28 {
      transition-delay: 1.4s; }
    .playerSelect__text.transitionIn .char--29 {
      transition-delay: 1.45s; }
    .playerSelect__text.transitionIn .char--30 {
      transition-delay: 1.5s; }
  .playerSelect__text .fontSize-player {
    font-size: 71px; }

.playerSelect__players {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  transform: scale(1.1) translateX(-60px); }

.player__first, .player__second {
  width: 200px;
  margin: auto 0;
  cursor: pointer; }
  .player__first img, .player__second img {
    width: 100%;
    position: absolute;
    transition: transform .2s ease-in-out; }
  .player__first:hover .player__head, .player__first.isActive .player__head, .player__second:hover .player__head, .player__second.isActive .player__head {
    transform: translateY(-20px); }
  .player__first:hover .player__name, .player__first.isActive .player__name, .player__second:hover .player__name, .player__second.isActive .player__name {
    transform: translate(calc(34% + 5px), calc(-103% - 3px)) skewY(5.125deg); }
    .player__first:hover .player__name:before, .player__first.isActive .player__name:before, .player__second:hover .player__name:before, .player__second.isActive .player__name:before {
      width: 20px;
      height: 30px;
      right: -20%;
      top: -6px; }
  .player__first:hover .player__name-content, .player__second:hover .player__name-content {
    transform: translateY(-100%); }
    .player__first:hover .player__name-content:before, .player__second:hover .player__name-content:before {
      transition: opacity 0.05s ease-in-out 0s;
      opacity: 1; }

.player__first {
  transform: translate(-5%, -8.4%); }

.player {
  position: relative;
  height: 300px; }
  .player__background {
    background-color: #c9c7cb;
    width: 90px;
    height: 180px;
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateY(-15%) translateX(10%) skewY(5.125deg); }
  .player__name {
    background-color: #a72b2e;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    position: absolute;
    text-transform: uppercase;
    color: white;
    transform: translate(34%, -103%) skewY(5.125deg);
    transition: transform .2s ease-in-out; }
    .player__name::before {
      display: inline-block;
      content: "";
      width: 25px;
      height: 30px;
      background-color: #cf363a;
      right: -25%;
      top: -7px;
      position: absolute;
      transform: skewY(-30.125deg);
      transition: all .2s ease-in-out; }

.player__name-content-container {
  display: block;
  overflow: hidden;
  line-height: 18px; }

.player__name-content {
  position: relative;
  display: block;
  transition: transform .2s ease-in-out; }
  .player__name-content:before {
    content: attr(data-content);
    position: absolute;
    left: 0;
    top: 100%;
    opacity: 0;
    transition: opacity 0.05s ease-in-out 0.2s; }

.player__head {
  position: absolute;
  left: 0;
  top: 0; }

.bg__transition {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 110vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: auto;
  pointer-events: none;
  z-index: 200;
  transform: rotate(-45deg);
  visibility: hidden;
  opacity: 0; }
  .bg__transition-red {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    margin: auto;
    width: 100%;
    height: 300px;
    background: #FC4846; }
  .bg__transition-white {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    margin: auto;
    width: 100%;
    height: 300px;
    background: white; }

@media screen and (max-width: 640px) {
  .bg__transition {
    display: none; }
  .playerSelect__text {
    font-size: 25px; }
    .playerSelect__text .fontSize-player {
      font-size: 35px; }
  .playerSelect__logo {
    left: 50%;
    transform: translateX(-50%); }
    .playerSelect__logo img {
      width: 100px; }
  .player__first {
    transform: translate(-5%, -3.4%); }
  .player {
    height: 260px; }
    .player__background {
      height: 140px;
      width: 70px; }
  .playerSelect__players {
    transform: scale(0.8) translateX(0px); } }

@media screen and (max-height: 500px) and (orientation: landscape) {
  .playerSelect__logo {
    top: 10px;
    left: 10px;
    width: 150px;
    height: auto; }
    .playerSelect__logo img {
      width: 100%;
      height: auto; }
  .playerSelect__text {
    margin-top: 20px;
    font-size: 24px; }
    .playerSelect__text .fontSize-player {
      font-size: 34px; }
  .playerSelect__players {
    margin-top: 50px;
    transform: scale(1.1) translateX(-32px); }
  .player__background {
    width: 54px;
    height: 107px; }
  .playerSelect__players .player {
    height: 200px;
    width: 120px; }
    .playerSelect__players .player:hover .player__name, .playerSelect__players .player.isActive .player__name {
      transform: translate(calc(34% + 5px), calc(-103% - 3px)) skewY(5.125deg); }
      .playerSelect__players .player:hover .player__name:before, .playerSelect__players .player.isActive .player__name:before {
        width: 20px;
        height: 20px;
        right: -20%;
        top: -6px; }
  .player__name {
    bottom: 20px;
    position: absolute;
    width: 80px;
    height: 20px;
    font-size: 10px;
    background-color: #a72b2e;
    transform: translate(34%, -103%) skewY(5.125deg); }
    .player__name::before {
      position: absolute;
      right: -19%;
      top: -4px;
      width: 15px;
      height: 20px;
      transform: skewY(-30.125deg); } }

.container__home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: red;
  visibility: hidden; }
  .container__home.hidden {
    visibility: hidden; }

.home__logo {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.home_start {
  position: absolute; }

.section-form {
  display: none;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #d93f3f; }

.section-form__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100); }

.section-form__title {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase; }
  .section-form__title span {
    text-transform: lowercase; }
  .section-form__title img {
    margin: 10px auto;
    display: block;
    width: 70%;
    height: auto; }

.section-form__date {
  font-size: 15px;
  background-color: #B2B2B2;
  color: black;
  padding: 5px;
  position: relative; }
  .section-form__date:before {
    position: absolute;
    left: 6px;
    bottom: 100%;
    width: 100%;
    height: 25%;
    background: white;
    content: '';
    transform: skewX(-60deg);
    transition: all .2s ease-in-out; }
  .section-form__date:after {
    position: absolute;
    right: -12px;
    top: -4px;
    transform: skewY(-30deg);
    width: 11%;
    height: 30px;
    content: "";
    background-color: #D3D2D1; }

.section-form__legal {
  position: absolute;
  top: 25px;
  right: 25px;
  text-transform: uppercase;
  color: white;
  text-decoration: none; }

.form__text-field-wrapper {
  display: flex;
  flex-direction: column; }

.form__input-wrapper--name {
  z-index: 3; }

.form__input-wrapper--lastname {
  z-index: 2; }

.form__input-wrapper--form__email {
  z-index: 1; }

.section-form__form {
  margin-top: 50px; }

.form__input-wrapper-submit {
  position: relative;
  margin: auto;
  margin-top: 60px;
  width: 200px;
  transition: transform .2s ease-in-out; }
  .form__input-wrapper-submit:hover {
    transform: translate(5px, -5px); }
    .form__input-wrapper-submit:hover:before {
      height: 20%;
      left: 10.5px; }
  .form__input-wrapper-submit:before {
    position: absolute;
    left: 12.5px;
    bottom: 100%;
    width: 100%;
    height: 30%;
    background: #ff4848;
    content: '';
    transform: skewX(-60deg);
    transition: all .2s ease-in-out; }

.form__submit {
  width: 100%;
  padding: 10px 25px;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  background-color: #b23435;
  cursor: pointer; }
  .form__submit:focus {
    color: white !important; }

.form__input-wrapper {
  position: relative;
  max-width: 480px;
  width: 70%;
  height: 50px;
  margin: 7px auto; }
  .form__input-wrapper:before {
    position: absolute;
    left: 12.5px;
    bottom: 100%;
    width: 100%;
    height: 30%;
    background: #ff4848;
    content: '';
    transform: skewX(-60deg); }

input {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  text-align: center;
  border: none;
  outline: none;
  font-size: 20px;
  font-family: "Krungthep", sans-serif; }

.section-form__image {
  position: absolute;
  top: 50%;
  height: 80%;
  max-height: 600px; }
  .section-form__image--left {
    left: 0;
    transform: translate(-28%, -50%); }
  .section-form__image--right {
    right: 0;
    transform: translate(28%, -50%); }

@media screen and (max-width: 640px) {
  .section-form__image {
    top: auto;
    bottom: 0;
    width: 20%;
    height: auto; }
    .section-form__image--left {
      transform: translate(-28%, 0); }
    .section-form__image--right {
      transform: translate(28%, 0); } }

@media screen and (max-height: 600px) {
  .section-form__image {
    top: auto;
    bottom: 0;
    height: 60%; }
  .section-form__title-separator {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px auto;
    vertical-align: top; }
  .section-form__title {
    text-align: center;
    font-size: 15px; }
    .section-form__title img {
      width: 20%;
      height: auto;
      margin: 10px auto;
      display: inline-block; }
  .form__input-wrapper {
    max-width: 350px;
    width: 60%;
    height: 30px;
    margin: 7px auto; }
    .form__input-wrapper:before {
      position: absolute;
      left: 12.5px;
      bottom: 100%;
      width: 100%;
      height: 30%;
      background: #ff4848;
      content: '';
      transform: skewX(-60deg); }
  input {
    font-size: 12px; }
  .section-form__form {
    margin-top: 20px; }
  .form__input-wrapper-submit {
    margin-top: 20px;
    width: 150px; }
    .form__input-wrapper-submit:before {
      left: 6.5px; }
    .form__input-wrapper-submit .form__submit {
      font-size: 12px;
      padding: 6px 20px; }
  .section-form__image--left {
    transform: translate(-28%, -35%) !important; }
  .section-form__image--right {
    transform: translate(28%, -35%) !important; } }

.site__legal {
  background-color: #ce3739;
  color: white;
  text-align: center;
  width: 100%;
  min-height: 100vh; }
  .site__legal p {
    margin: 40px auto;
    width: 70%;
    line-height: 25px; }
  .site__legal h1 {
    text-align: center;
    font-size: 35px;
    margin: 50px 10px 20px 10px; }
  .site__legal h3 {
    font-size: 25px;
    margin: 20px 20px; }

@media screen and (max-width: 640px) {
  .site__legal p {
    width: 90%;
    font-size: 14px; }
  .site__legal h1 {
    font-size: 25px; }
  .site__legal h3 {
    font-size: 16px; } }

.section-mobile-orientation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #ce3739; }
  .section-mobile-orientation.isDisable {
    display: none;
    visibility: hidden;
    pointer-events: none; }

.section-mobile-orientation__message {
  text-align: center;
  display: block;
  margin: auto;
  color: white; }

.section-mobile-orientation__image {
  display: block;
  width: 70%;
  height: auto;
  margin: auto;
  margin-bottom: 40px; }

.section-cinematic {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #d83d3f;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }

.section-cinematic__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto; }

.section-form-validation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;
  display: none;
  background-image: url("../assets/images/seats.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #d83d3f; }

.section-form-validation__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  width: 75%;
  height: 100%;
  margin: auto; }
  @media screen and (max-width: 560px) {
    .section-form-validation__container {
      width: 85%; }
      .section-form-validation__container .section-form-validation__paragraph {
        font-size: 30px; }
      .section-form-validation__container .section-form-validation__button {
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px; } }

.section-form-validation__players-images-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 35%; }
  @media screen and (max-width: 1100px) {
    .section-form-validation__players-images-container {
      display: none; } }

.section-form-validation__player-image-container {
  position: relative;
  width: 50%; }
  .section-form-validation__player-image-container img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    transform: translateY(-50%); }

.section-form-validation__content {
  width: 50%;
  color: white; }
  @media screen and (max-width: 1100px) {
    .section-form-validation__content {
      width: 100%; } }

.section-form-validation__validation-box {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
  margin-right: 80px;
  background-color: #229931; }
  @media screen and (max-width: 1324px) {
    .section-form-validation__validation-box {
      width: 60px;
      height: 60px; } }
  .section-form-validation__validation-box svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 50%;
    height: 50%; }
    .section-form-validation__validation-box svg path {
      fill: white; }
  .section-form-validation__validation-box:before {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 35%;
    content: '';
    background-color: #33CC3C;
    display: block;
    transform: skewX(-40deg);
    transform-origin: left bottom; }
  .section-form-validation__validation-box:after {
    position: absolute;
    left: 100%;
    bottom: 0;
    width: 30%;
    height: 100%;
    content: '';
    background-color: #29B236;
    transform: skewY(-50deg);
    transform-origin: left top;
    border-top: 1px solid #29B236; }

.section-form-validation__paragraph {
  display: inline-block;
  font-size: 45px; }
  @media screen and (max-width: 1324px) {
    .section-form-validation__paragraph {
      font-size: 35px; } }

.section-form-validation__social {
  font-size: 15px;
  line-height: 20px;
  margin-top: 40px;
  opacity: 0.7; }

.section-form-validation__buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 60px; }

.section-form-validation__button {
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  width: auto;
  min-width: 70px;
  height: 60px;
  box-sizing: border-box;
  padding: 0 30px;
  line-height: 60px;
  font-size: 20px;
  text-transform: uppercase;
  background: #a72b2e;
  border: none;
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease-in-out; }
  .section-form-validation__button::before {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 30%;
    background-color: #FC4846;
    content: "";
    transform-origin: left bottom;
    transform: skewX(-40deg);
    transition: height 0.2s ease-in-out; }
  .section-form-validation__button:hover {
    transform: translate(5px, -5px); }
    .section-form-validation__button:hover:before {
      height: 22%; }

.section-form-validation__button-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: auto; }

.section-form-validation__credits-container {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: space-around; }
  @media screen and (max-width: 1080px) {
    .section-form-validation__credits-container span {
      max-width: 120px; } }
  @media screen and (max-width: 1080px) {
    .section-form-validation__credits-container {
      flex-flow: column;
      text-align: center; }
      .section-form-validation__credits-container span {
        max-width: 100%;
        margin: 1px 0; } }

@media screen and (orientation: landscape) and (max-height: 420px) {
  .section-form-validation__credits-container {
    display: none; } }

.canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.canvas-container {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: left top;
  pointer-events: none;
  width: 100%;
  height: 100%; }

.game__infos-borders {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 12px solid #ce3739;
  box-sizing: border-box; }

.game__infos-logo-jahneration {
  width: 200px;
  position: absolute;
  top: 20px;
  left: 20px; }

.game__infos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100); }

.infos__timer {
  color: white;
  font-size: 65px;
  z-index: 0;
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 450px;
  height: 300px;
  transform: skewX(4.5deg) rotate(4.5deg);
  pointer-events: all; }
  .infos__timer .timer-value {
    position: absolute;
    right: 30%;
    text-shadow: rgba(0, 0, 0, 0.4) 5px -3px; }
  .infos__timer .timer-counter {
    width: 250px;
    height: 250px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: black; }
  .infos__timer .timer-box .box-front {
    position: absolute;
    background-color: #7f2224;
    height: 350px;
    width: 350px;
    bottom: -125px; }
  .infos__timer .timer-box .box-top {
    position: absolute;
    background-color: #f74040;
    height: 350px;
    width: 350px;
    transform: skewX(-55deg);
    bottom: 75%;
    right: -150px; }
  .infos__timer .timer-box .box-right {
    position: absolute;
    height: 350px;
    width: 350px;
    right: -250px;
    bottom: -2px;
    background-color: #8a2525;
    transform: skewY(-35deg); }
  .infos__timer .timer-box .progress-bar {
    position: absolute;
    width: 350px;
    height: 150px;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .infos__timer .timer-box .progress-bar .progress-bar-lines {
      width: 265px;
      position: absolute;
      height: 80px;
      display: flex;
      right: -10px;
      bottom: 20px; }
      .infos__timer .timer-box .progress-bar .progress-bar-lines .line {
        width: calc(100% / 3); }
        .infos__timer .timer-box .progress-bar .progress-bar-lines .line:after {
          width: 0px;
          font-size: 30px;
          content: "|";
          letter-spacing: 0;
          position: absolute; }
      .infos__timer .timer-box .progress-bar .progress-bar-lines .line-right {
        text-align: right; }
      .infos__timer .timer-box .progress-bar .progress-bar-lines .line-centered {
        text-align: center; }
    .infos__timer .timer-box .progress-bar .progress-bar-transparent {
      position: absolute;
      height: 30px;
      width: 265px; }
      .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-front {
        position: absolute;
        height: 30px;
        width: 265px;
        background-color: rgba(255, 255, 255, 0.2); }
        .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-front .front-progress {
          position: absolute;
          left: 0;
          width: 0;
          height: 100%;
          background-color: #b2b2b2; }
      .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-head {
        position: absolute;
        height: 30px;
        width: 265px;
        bottom: 100px; }
        .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-head .head-progress {
          position: absolute;
          left: 0;
          width: 0;
          height: 100%; }
          .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-head .head-progress img {
            position: absolute;
            right: 0;
            transform: translateX(60%) rotate(-5deg);
            width: 100px; }
      .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-top {
        position: absolute;
        height: 15px;
        width: 265px;
        background-color: rgba(255, 255, 255, 0.6);
        bottom: 30px;
        right: -10px;
        transform: skewX(-55deg); }
        .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-top .top-progress {
          position: absolute;
          left: 0;
          width: 0;
          height: 100%;
          background-color: #fff; }
      .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-right {
        position: absolute;
        height: 30px;
        bottom: 7px;
        right: -20px;
        width: 20px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: skewY(-35deg); }
  @media screen and (max-width: 1250px) {
    .infos__timer {
      bottom: 20px;
      right: 20px;
      transform: skewX(0) rotate(0);
      width: 350px;
      height: 100px; }
      .infos__timer.is-fullscreen-enable {
        right: calc(20px + 60px + 10px); }
      .infos__timer .timer-box {
        width: 100%;
        height: 100%; }
        .infos__timer .timer-box .box-front {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #ce3739; }
        .infos__timer .timer-box .box-top {
          display: none; }
        .infos__timer .timer-box .box-right {
          display: none; }
        .infos__timer .timer-box .progress-bar {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: block; }
          .infos__timer .timer-box .progress-bar .progress-bar-lines {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: auto;
            height: 70px;
            margin: auto; }
            .infos__timer .timer-box .progress-bar .progress-bar-lines .line:after {
              position: absolute;
              left: 0;
              top: 15px;
              height: 15px;
              width: 0;
              border: solid 1px white;
              content: ''; }
            .infos__timer .timer-box .progress-bar .progress-bar-lines .line.line-centered:after {
              left: 0;
              right: 0;
              margin: auto; }
            .infos__timer .timer-box .progress-bar .progress-bar-lines .line.line-right:after {
              left: auto;
              right: 0; }
          .infos__timer .timer-box .progress-bar .progress-bar-transparent {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            width: 85%;
            margin: auto; }
            .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-lines {
              width: 100%; }
            .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-front {
              width: 100%; }
            .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-head {
              top: 0;
              width: 100%; }
              .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-head .head-progress {
                top: 0;
                left: 0; }
                .infos__timer .timer-box .progress-bar .progress-bar-transparent .progress-bar-head .head-progress img {
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  z-index: 1; }
          .infos__timer .timer-box .progress-bar .progress-bar-front {
            width: 100%; }
            .infos__timer .timer-box .progress-bar .progress-bar-front .front-progress {
              background-color: white !important; }
          .infos__timer .timer-box .progress-bar .progress-bar-top {
            display: none; }
          .infos__timer .timer-box .progress-bar .progress-bar-right {
            display: none; }
      .infos__timer .timer-value {
        position: absolute;
        top: -45px;
        right: auto;
        left: 5%;
        text-shadow: transparent 2px 5px; } }
  @media screen and (orientation: landscape) and (max-width: 1000px) and (max-height: 600px) {
    .infos__timer {
      transform-origin: right bottom;
      transform: scale(0.6); }
      .infos__timer.is-fullscreen-enable {
        right: calc(20px + 36px + 10px); } }

.section-looser {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  background-color: #D83D3F;
  z-index: 9999; }

.section-looser__box-container {
  position: relative; }
  @media screen and (orientation: landscape) and (max-width: 1000px) and (max-height: 500px) {
    .section-looser__box-container {
      transform: scale(0.6); } }

.section-looser__box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0px;
  transform: skewY(2.6deg); }

.section-looser__box-top {
  position: absolute;
  left: 0;
  bottom: 95%;
  width: 100%;
  height: 100%;
  background-color: #FC4846;
  content: '';
  transform-origin: center bottom;
  transform: rotateX(83deg) skewX(-10deg); }

.section-looser__box-right {
  position: absolute;
  left: 100%;
  top: 5%;
  width: 62%;
  height: 100%;
  background-color: #cf363a;
  content: '';
  transform-origin: left top;
  transform: rotateY(83deg) skewY(-4.9deg); }

.section-looser__content {
  transform-origin: left bottom;
  transform: skewY(2.6deg);
  padding: 60px 40px 20px 40px;
  background-color: #a72b2e;
  overflow: hidden; }

.section-looser__heading {
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 90px; }
  .section-looser__heading--shadow {
    position: absolute;
    left: 0;
    top: 0;
    color: white; }

.section-looser__subheading {
  margin-top: 20px;
  text-align: center;
  color: white;
  font-size: 30px;
  animation: blink .3s infinite alternate; }

@keyframes blink {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@media screen and (max-height: 400px) {
  .section-looser__box-container {
    transform: scale(0.6); } }

.game__infos__countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transform: skewX(4.5deg) rotate(4.5deg); }
  @media screen and (orientation: landscape) and (max-width: 1000px) and (max-height: 500px) {
    .game__infos__countdown {
      transform: skewX(4.5deg) rotate(4.5deg) scale(0.6); } }

.countdown__box-container {
  position: relative;
  padding: 30px 50px;
  text-align: center;
  font-size: 90px;
  background-color: #7f2224;
  color: white;
  pointer-events: none; }

.countdown__box-top {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FC4846;
  transform-origin: left bottom;
  transform: skewX(-55deg) scaleY(0.25); }

.countdown__box-front {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.countdown__box-right {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: #cf363a;
  transform-origin: left top;
  transform: skewY(-35deg) scaleX(0.34); }

.countdown__content {
  display: inline-block; }

.game__infos__controlsIndications {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  text-transform: uppercase; }

.game__infos__controlsIndications__container {
  position: relative;
  transform: skewX(4.5deg) rotate(4.5deg); }

.game__infos__controlsIndications__title-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center; }

.game__infos__controlsIndications__key-content-container {
  overflow: hidden; }

.game__infos__controlsIndications__title {
  display: inline-block;
  position: relative;
  padding: 5px 14px;
  color: black;
  background-color: #B2B2B2;
  font-size: 22px; }

.game__infos__controlsIndications__content {
  max-width: 400px;
  min-height: 240px;
  padding: 60px 50px;
  padding-bottom: 80px;
  background: #B23435;
  color: #B2B2B2;
  text-transform: none;
  font-size: 30px;
  line-height: 40px; }

.game__infos__controlsIndications__key-container {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translate(-5%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px; }

.game__infos__controlsIndications__key {
  position: relative;
  background: #B23435;
  text-align: center;
  width: 350px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  margin: auto; }
  .game__infos__controlsIndications__key--left, .game__infos__controlsIndications__key--right {
    width: 65px;
    height: 40px;
    position: relative;
    margin: 0 10px; }
    .game__infos__controlsIndications__key--left .key__boxRight, .game__infos__controlsIndications__key--right .key__boxRight {
      width: 40%; }

.game__infos__controlsIndications__check {
  width: 40px;
  height: 40px;
  display: none; }

.game__infos__controlsIndications__key-content-container {
  overflow: hidden; }

.title__boxTop {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  height: 30%;
  background-color: #FFFFFF;
  border-right: solid 1px #fff;
  transform-origin: left bottom;
  transform: skewX(-55deg);
  font-size: 0; }

.title__boxRight {
  position: absolute;
  left: 100%;
  top: 0;
  width: 8%;
  height: 100%;
  background-color: #D3D2D1;
  transform-origin: left bottom;
  transform: skewY(-35deg);
  font-size: 0; }

.key__boxTop {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  height: 30%;
  background-color: #FC4846;
  transform-origin: left bottom;
  transform: skewX(-55deg);
  font-size: 0; }

.key__boxRight {
  position: absolute;
  left: 100%;
  top: 0;
  width: 8%;
  height: 100%;
  background-color: #cf363a;
  transform-origin: left bottom;
  transform: skewY(-35deg);
  font-size: 0; }

@media screen and (max-height: 600px) {
  .game__infos__controlsIndications {
    transform: scale(0.6); } }

.container__loader {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #d83d3f;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999; }
  .container__loader .loader__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    display: flex; }
    .container__loader .loader__content .content__logo {
      width: 150px; }
    .container__loader .loader__content .content__progress {
      color: white;
      margin-top: 5%;
      display: inline-block;
      opacity: 0;
      transform: translateY(100%); }

.fullscreen-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border: none;
  outline: none;
  background: none;
  box-sizing: border-box;
  padding: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.1s ease-in-out 0s; }
  .fullscreen-button.is-enable {
    opacity: 1;
    display: block;
    transition: opacity 0.1s ease-in-out 0.5s; }

.fullscreen-button__image {
  width: 100%;
  height: 100%; }

@media screen and (orientation: landscape) and (max-width: 1000px) and (max-height: 500px) {
  .fullscreen-button {
    transform-origin: right bottom;
    transform: scale(0.6); } }

.sound-button {
  display: none;
  align-items: center;
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 60px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  background-color: #d83d3f; }
  .sound-button.is-active {
    display: flex; }

.sound-button__icon {
  height: 80%;
  width: 40%;
  margin: auto 0; }

.sound-button__lines-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50%; }

.sound-button__line {
  width: 4px;
  height: 20px;
  margin: 0 1px;
  background: white;
  transform-origin: left bottom;
  transform: scaleY(0.15); }
  .sound-button__line:last-child {
    margin-right: 0; }

.game-level {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  text-transform: uppercase;
  color: white; }

.contest-over {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: opacity 0.3s ease-in-out; }
  .contest-over.transitionIn {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; }
  .contest-over.transitionOut {
    display: none; }

.contest-over__background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #FC4846;
  opacity: 0.8; }

.contest-over__message {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  color: white;
  cursor: pointer; }

.log {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000000;
  font-size: 100;
  background: blue; }

.webview {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background: blue;
  color: white; }

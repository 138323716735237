.site__legal {
    background-color: $bordersRed;
    color: white;
    text-align: center;

    width: 100%;
    min-height: 100vh;
    p {
        margin: 40px auto;

        width: 70%;
        line-height: 25px; }
    h1 {
        text-align: center;
        font-size: 35px;

        margin: 50px 10px 20px 10px; }

    h3 {
        font-size: 25px;

        margin: 20px 20px; } }

@media screen and (max-width: 640px) {
    .site__legal {
        p {
            width: 90%;
            font-size: 14px; }
        h1 {
            font-size: 25px; }

        h3 {
            font-size: 16px; } } }

.section-form {
    display: none;
    visibility: hidden;
    opacity: 0;

    z-index: 99;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #d93f3f; }


.section-form__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100); }

.section-form__title {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    color: white;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;

    span {
        text-transform: lowercase; }

    img {
        margin: 10px auto;
        display: block;

        width: 70%;
        height: auto; } }

.section-form__date {
    font-size: 15px;

    background-color: #B2B2B2;
    color: black;

    padding: 5px;

    position: relative;
    &:before {
        position: absolute;
        left: 6px;
        bottom: 100%;

        width: 100%;
        height: 25%;

        background: white;

        content: '';

        transform: skewX(-60deg);

        transition: all .2s ease-in-out; }

    &:after {
        position: absolute;
        right: -12px;
        top: -4px;
        transform: skewY(-30deg);

        width: 11%;
        height: 30px;

        content: "";

        background-color: #D3D2D1; } }
.section-form__legal {
    position: absolute;
    top: 25px;
    right: 25px;

    text-transform: uppercase;
    color: white;
    text-decoration: none; }

.form__text-field-wrapper {
    display: flex;
    flex-direction: column; }


.form__input-wrapper {
    &--name {
        z-index: 3; }
    &--lastname {
        z-index: 2; }
    &--form__email {
        z-index: 1; } }

.section-form__form {
    margin-top: 50px; }

.form__input-wrapper-submit {
    position: relative;
    margin: auto;
    margin-top: 60px;
    width: 200px;

    transition: transform .2s ease-in-out;

    &:hover {
        transform: translate(5px, -5px);
        &:before {
            height: 20%;
            left: 10.5px; } }

    &:before {
        position: absolute;
        left: 12.5px;
        bottom: 100%;

        width: 100%;
        height: 30%;

        background: #ff4848;

        content: '';

        transform: skewX(-60deg);

        transition: all .2s ease-in-out; } }


.form__submit {
    width: 100%;
    padding: 10px 25px;

    font-size: 20px;
    text-transform: uppercase;
    color: white;
    background-color: #b23435;

    cursor: pointer;

    &:focus {
        color: white!important; } }


.form__input-wrapper {
    position: relative;

    max-width: 480px;
    width: 70%;
    height: 50px;

    margin: 7px auto;

    &:before {
        position: absolute;
        left: 12.5px;
        bottom: 100%;

        width: 100%;
        height: 30%;

        background: #ff4848;

        content: '';

        transform: skewX(-60deg); } }


input {
    display: block;
    width: 100%;
    height: 100%;

    padding: 0;

    text-align: center;

    border: none;
    outline: none;

    font-size: 20px;
    font-family: $krungthep, sans-serif; }

.section-form__image {
    position: absolute;
    top: 50%;

    height: 80%;
    max-height: 600px;

    &--left {
        left: 0;
        transform: translate(-28%,-50%); }
    &--right {
        right: 0;
        transform: translate(28%,-50%); } }

@media screen and (max-width: 640px) {
    .section-form__image {
        top: auto;
        bottom: 0;
        width: 20%;
        height: auto;

        &--left {
            transform: translate(-28%,0); }
        &--right {
            transform: translate(28%,0); } } }


// @media screen and (orientation: landscape)
//     .section-form
//         width: 100vh
//         height: 100vw

//         transform-origin: center
//         transform: rotate(90deg)


@media screen and (max-height: 600px) {
    .section-form__image {
        top: auto;
        bottom: 0;
        height: 60%; }

    .section-form__title-separator {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;

        margin: 10px auto;
        vertical-align: top; }

    .section-form__title {
        text-align: center;
        font-size: 15px;

        img {
            width: 20%;
            height: auto;

            margin: 10px auto;
            display: inline-block; } }


    .form__input-wrapper {
        max-width: 350px;
        width: 60%;
        height: 30px;

        margin: 7px auto;

        &:before {
            position: absolute;
            left: 12.5px;
            bottom: 100%;

            width: 100%;
            height: 30%;

            background: #ff4848;

            content: '';

            transform: skewX(-60deg); } }

    input {
        font-size: 12px; }

    .section-form__form {
        margin-top: 20px; }

    .form__input-wrapper-submit {
        margin-top: 20px;

        width: 150px;

        &:before {
            left: 6.5px; }

        .form__submit {
            font-size: 12px;
            padding: 6px 20px; } }

    .section-form__image {
        &--left {
            transform: translate(-28%,-35%) !important; }
        &--right {
            transform: translate(28%,-35%) !important; } } }
